import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Paper,
  Grid,
  Alert,
  useMediaQuery,
  useTheme,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Tooltip,
} from "@mui/material";
import moment from "moment-timezone";
import { supabase } from "../utils/supabase";
import CancelIcon from "@mui/icons-material/Cancel";
import EventIcon from "@mui/icons-material/Event";

const formatDateTime = (dateString, timeString, timezone) => {
  const [start_time] = timeString.split("-");
  const dateTime = `${dateString}T${start_time}:00Z`;
  const localDateTime = moment.tz(dateTime, timezone);

  const formattedDate = localDateTime.format("MMMM Do, YYYY");
  const formattedTime = localDateTime.format("h:mm A");

  return { formattedDate, formattedTime };
};

const MatchedPageDetails = ({ matchedDetails, scpDetails, timezone }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(
    matchedDetails?.status || null
  );
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  if (
    Object.keys(matchedDetails).length === 0 ||
    Object.keys(scpDetails).length === 0 ||
    !timezone
  ) {
    return null;
  }

  const { meeting_date, meeting_time, id: appointmentId } = matchedDetails;
  const { scp_credentials, name } = scpDetails;

  const { formattedDate, formattedTime } = formatDateTime(
    meeting_date,
    meeting_time,
    timezone
  );

  const generateGoogleCalendarLink = () => {
    const [start_time] = meeting_time.split("-");
    const dateTime = `${meeting_date}T${start_time}:00Z`;
    const startDate = moment.utc(dateTime).format("YYYYMMDDTHHmmss[Z]");
    const endDate = moment
      .utc(dateTime)
      .add(1, "hour")
      .format("YYYYMMDDTHHmmss[Z]");

    const details = `Meeting with ${name}`;
    const text = `Meeting with ${name}`;

    return `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${startDate}%2F${endDate}&details=${encodeURIComponent(
      details
    )}&text=${encodeURIComponent(text)}`;
  };

  const handleCancelMeeting = async () => {
    setIsCancelling(true);
    try {
      const { error } = await supabase
        .from("appointments")
        .update({ status: "cancelled" })
        .eq("id", appointmentId);

      if (error) throw error;
      setCurrentStatus("cancelled");
      setSnackbar({
        open: true,
        message: "Meeting cancelled successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error cancelling meeting:", error);
      setSnackbar({
        open: true,
        message: "Failed to cancel meeting",
        severity: "error",
      });
    } finally {
      setIsCancelling(false);
      setOpenDialog(false);
    }
  };

  const status = currentStatus || matchedDetails.status;

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      {status === "cancelled" ? (
        <Alert severity="error" sx={{ mb: 4 }}>
          This meeting has been cancelled.
        </Alert>
      ) : (
        <Alert severity="warning" sx={{ mb: 4 }}>
          You have arrived before the permitted time. Please wait until your
          scheduled meeting.
        </Alert>
      )}

      <Box mb={4} textAlign={isMobile ? "center" : "left"}>
        {status === "cancelled" ? (
          <>
            <Typography variant="h4" component="div" gutterBottom>
              Meeting Status: Cancelled
            </Typography>
            <Typography variant="body1" color="text.secondary">
              This meeting has been cancelled. You can still view the details of
              your scheduled meeting partner below, or return to your dashboard
              to schedule a new meeting.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h4" component="div" gutterBottom>
              Meeting Details Preview
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Your meeting will begin at the scheduled time. In the meantime,
              you can review your meeting details and get to know your meeting
              partner through their profile information below.
            </Typography>
          </>
        )}
      </Box>

      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">Meeting Information</Typography>
          {status !== "cancelled" && (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Tooltip title="Add to Calendar" arrow>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={!isMobile && <EventIcon sx={{ fontSize: 18 }} />}
                  href={generateGoogleCalendarLink()}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    backgroundColor: "#fff !important",
                    border: "1px solid #1976d2 !important",
                    color: "#1976d2 !important",
                    borderRadius: "4px",
                    textTransform: "none",
                    padding: "4px 12px",
                    minHeight: "32px",
                    minWidth: isMobile ? "32px !important" : "auto !important",
                    fontSize: "14px",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "rgba(25, 118, 210, 0.04) !important",
                      boxShadow: "none",
                    },
                  }}
                >
                  {isMobile ? (
                    <EventIcon sx={{ fontSize: 18 }} />
                  ) : (
                    "Add to Calendar"
                  )}
                </Button>
              </Tooltip>
              <Tooltip title="Cancel Meeting" arrow>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={!isMobile && <CancelIcon sx={{ fontSize: 18 }} />}
                  onClick={() => setOpenDialog(true)}
                  sx={{
                    backgroundColor: "#fff !important",
                    border: "1px solid #d32f2f !important",
                    color: "#d32f2f !important",
                    borderRadius: "4px",
                    textTransform: "none",
                    padding: "4px 12px",
                    minHeight: "32px",
                    minWidth: isMobile ? "32px !important" : "auto !important",
                    fontSize: "14px",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "rgba(211, 47, 47, 0.04) !important",
                      boxShadow: "none",
                    },
                  }}
                >
                  {isMobile ? (
                    <CancelIcon sx={{ fontSize: 18 }} />
                  ) : (
                    "Cancel Meeting"
                  )}
                </Button>
              </Tooltip>
            </Box>
          )}
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1">
              <strong>Meeting Date:</strong> {formattedDate}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1">
              <strong>Meeting Time:</strong> {formattedTime} ({timezone})
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1">
              <strong>Meeting Partner:</strong> {name}
            </Typography>
          </Grid>
          {status === "cancelled" && (
            <Grid item xs={12}>
              <Typography variant="body1" color="error">
                <strong>Status:</strong> Cancelled
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>

      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h6" gutterBottom>
          Explore Their Profile!
        </Typography>
        <Divider sx={{ mb: 2 }} />
        {scp_credentials ? (
          <iframe
            src={scp_credentials}
            width="100%"
            height="400px"
            style={{ border: "none" }}
            title="Meeting Partner Profile"
          />
        ) : (
          <Typography variant="body1" color="text.secondary">
            Profile information is not available at the moment.
          </Typography>
        )}
      </Paper>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: 400,
            p: 2,
          },
        }}
      >
        <DialogTitle>Cancel Meeting</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to cancel this meeting? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>No, Keep Meeting</Button>
          <Button
            onClick={handleCancelMeeting}
            color="error"
            variant="contained"
            disabled={isCancelling}
          >
            {isCancelling ? "Cancelling..." : "Yes, Cancel Meeting"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        sx={{
          "& .MuiSnackbarContent-root": {
            display: "flex",
            alignItems: "center",
            gap: 1,
            minHeight: "48px",
          },
        }}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          sx={{
            width: "100%",
            alignItems: "center",
            "& .MuiAlert-action": {
              padding: "0 8px",
              marginRight: 0,
              alignSelf: "center",
            },
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default MatchedPageDetails;
