import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import the layouts
import Home from "./layouts/Home";
import PreferencesLayout from "./layouts/PreferencesLayout";
import PatientIntake from "./layouts/PatientIntake";
import JitsiMeetComponent from "./layouts/JitsiMeetComponent";
import SchedulerPage from "./layouts/SchedulerPage";
import MeetingFeedback from "./layouts/MeetingFeedback";
import Invitation from "./layouts/Invitation";
import PersonalScheduler from "./layouts/PersonalScheduler";
import ScreeningCopilot from "./layouts/ScreeningCopilot";
import ScreeningBackend from "./layouts/ScreeningBackend";
import Booking from "./layouts/Booking";
import BookAll from "./layouts/BookAll";
import Scholar from "./layouts/Scholar";
import CaptureTz from "./layouts/CaptureTz";
import ProtectedRoute from "./layouts/ProtectedRoute";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/preferences" element={<PreferencesLayout />} />
        <Route path="/invitation" element={<Invitation />} />
        <Route path="/personal-scheduler" element={<PersonalScheduler />} />
        <Route path="/patient" element={<PatientIntake />} />
        <Route path="/meeting" element={<JitsiMeetComponent />} />
        <Route path="/meeting-feedback" element={<MeetingFeedback />} />
        <Route path="/sdo" element={<SchedulerPage />} />
        <Route path="/screening-backend" element={<ScreeningBackend />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/book-all" element={<BookAll />} />
        <Route path="/scholar" element={<Scholar />} />
        <Route path="/availability-discussion" element={<CaptureTz />} />
        <Route
          path="/screening-copilot"
          element={
            // <ProtectedRoute>
            <ScreeningCopilot />
            // </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
