import { supabase } from "../utils/supabase";
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";

const formatDate = (date) => format(date, "yyyy-MM-dd");

export const getTotalMeetingsToday = async () => {
  const today = formatDate(new Date());

  const { data, error } = await supabase
    .from("appointments")
    .select("*", { count: "exact" })
    .eq("meeting_date", today);

  if (error) console.error(error);
  return data;
};

export const getTotalMeetingsThisWeek = async () => {
  const today = new Date();
  const firstDayOfWeek = formatDate(
    new Date(today.setDate(today.getDate() - today.getDay()))
  );
  const lastDayOfWeek = formatDate(
    new Date(today.setDate(today.getDate() - today.getDay() + 6))
  );

  const { data, error } = await supabase
    .from("appointments")
    .select("*", { count: "exact" })
    .gte("meeting_date", firstDayOfWeek)
    .lte("meeting_date", lastDayOfWeek);

  if (error) console.error(error);
  return data;
};

export const getTotalMeetingsThisMonth = async () => {
  const today = new Date();
  const firstDayOfMonth = formatDate(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );
  const lastDayOfMonth = formatDate(
    new Date(today.getFullYear(), today.getMonth() + 1, 0)
  );

  const { data, error } = await supabase
    .from("appointments")
    .select("*", { count: "exact" })
    .gte("meeting_date", firstDayOfMonth)
    .lte("meeting_date", lastDayOfMonth);

  if (error) console.error(error);
  return data;
};

export const getWellScheduledMeetingsToday = async () => {
  const today = formatDate(new Date());

  const { data, error } = await supabase
    .from("appointments")
    .select("scp_id, patient_id, meeting_date")
    .eq("meeting_date", today);

  if (error) {
    console.error(error);
    return [];
  }

  // Count the meetings for each SCP and patient
  const counts = data.reduce((acc, appointment) => {
    if (!acc[appointment.scp_id]) {
      acc[appointment.scp_id] = { user_id: appointment.scp_id, count: 0 };
    }
    if (!acc[appointment.patient_id]) {
      acc[appointment.patient_id] = {
        user_id: appointment.patient_id,
        count: 0,
      };
    }
    acc[appointment.scp_id].count += 1;
    acc[appointment.patient_id].count += 1;
    return acc;
  }, {});

  // Convert counts to an array, sort them by count, and get the top 3
  const countArray = Object.values(counts);
  const topUsers = countArray.sort((a, b) => b.count - a.count).slice(0, 3);

  return topUsers;
};

export const getWellScheduledMeetingsThisWeek = async () => {
  const today = new Date();
  const firstDayOfWeek = formatDate(startOfWeek(today, { weekStartsOn: 1 }));
  const lastDayOfWeek = formatDate(endOfWeek(today, { weekStartsOn: 1 }));

  const { data, error } = await supabase
    .from("appointments")
    .select("scp_id, patient_id, meeting_date")
    .gte("meeting_date", firstDayOfWeek)
    .lte("meeting_date", lastDayOfWeek);

  if (error) {
    console.error(error);
    return [];
  }

  // Count the meetings for each SCP and patient
  const counts = data.reduce((acc, appointment) => {
    if (!acc[appointment.scp_id]) {
      acc[appointment.scp_id] = { user_id: appointment.scp_id, count: 0 };
    }
    if (!acc[appointment.patient_id]) {
      acc[appointment.patient_id] = {
        user_id: appointment.patient_id,
        count: 0,
      };
    }
    acc[appointment.scp_id].count += 1;
    acc[appointment.patient_id].count += 1;
    return acc;
  }, {});

  // Convert counts to an array, sort them by count, and get the top 3
  const countArray = Object.values(counts);
  const topUsers = countArray.sort((a, b) => b.count - a.count).slice(0, 3);

  return topUsers;
};

export const getWellScheduledMeetingsThisMonth = async () => {
  const today = new Date();
  const firstDayOfMonth = formatDate(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );
  const lastDayOfMonth = formatDate(
    new Date(today.getFullYear(), today.getMonth() + 1, 0)
  );

  const { data, error } = await supabase
    .from("appointments")
    .select("scp_id, patient_id, meeting_date")
    .gte("meeting_date", firstDayOfMonth)
    .lte("meeting_date", lastDayOfMonth);

  if (error) {
    console.error(error);
    return [];
  }

  // Count the meetings for each SCP and patient
  const counts = data.reduce((acc, appointment) => {
    if (!acc[appointment.scp_id]) {
      acc[appointment.scp_id] = { user_id: appointment.scp_id, count: 0 };
    }
    if (!acc[appointment.patient_id]) {
      acc[appointment.patient_id] = {
        user_id: appointment.patient_id,
        count: 0,
      };
    }
    acc[appointment.scp_id].count += 1;
    acc[appointment.patient_id].count += 1;
    return acc;
  }, {});

  // Convert counts to an array, sort them by count, and get the top 3
  const countArray = Object.values(counts);
  const topUsers = countArray.sort((a, b) => b.count - a.count).slice(0, 3);

  return topUsers;
};

export const getLeastScheduledMeetingsToday = async () => {
  const today = formatDate(new Date());

  // Fetch all users from the scp table
  const { data: users, error: userError } = await supabase
    .from("scp")
    .select("id");

  if (userError) {
    console.error(userError);
    return [];
  }

  // Fetch today's appointments
  const { data: appointments, error: appointmentError } = await supabase
    .from("appointments")
    .select("scp_id, patient_id, meeting_date")
    .eq("meeting_date", today);

  if (appointmentError) {
    console.error(appointmentError);
    return [];
  }

  // Initialize counts for all users
  const counts = users.reduce((acc, user) => {
    acc[user.id] = { user_id: user.id, count: 0 };
    return acc;
  }, {});

  // Count the meetings for each SCP and patient
  appointments.forEach((appointment) => {
    if (counts[appointment.scp_id]) {
      counts[appointment.scp_id].count += 1;
    }
    if (counts[appointment.patient_id]) {
      counts[appointment.patient_id].count += 1;
    }
  });

  // Convert counts to an array and sort them by count in ascending order
  const countArray = Object.values(counts).sort((a, b) => a.count - b.count);

  // Filter users with more than one meeting and limit them to top 3
  const moreThanOneMeeting = countArray
    .filter((user) => user.count > 1)
    .slice(0, 3);

  // Filter users with zero or one meeting
  const zeroOrOneMeeting = countArray.filter((user) => user.count <= 1);

  // Combine the results
  const result = [...zeroOrOneMeeting, ...moreThanOneMeeting];

  return result;
};

export const getLeastScheduledMeetingsThisWeek = async () => {
  const today = new Date();
  const firstDayOfWeek = formatDate(startOfWeek(today, { weekStartsOn: 1 }));
  const lastDayOfWeek = formatDate(endOfWeek(today, { weekStartsOn: 1 }));

  // Fetch all users from the scp table
  const { data: users, error: userError } = await supabase
    .from("scp")
    .select("id");

  if (userError) {
    console.error(userError);
    return [];
  }

  // Fetch this week's appointments
  const { data: appointments, error: appointmentError } = await supabase
    .from("appointments")
    .select("scp_id, patient_id, meeting_date")
    .gte("meeting_date", firstDayOfWeek)
    .lte("meeting_date", lastDayOfWeek);

  if (appointmentError) {
    console.error(appointmentError);
    return [];
  }

  // Initialize counts for all users
  const counts = users.reduce((acc, user) => {
    acc[user.id] = { user_id: user.id, count: 0 };
    return acc;
  }, {});

  // Count the meetings for each SCP and patient
  appointments.forEach((appointment) => {
    if (counts[appointment.scp_id]) {
      counts[appointment.scp_id].count += 1;
    }
    if (counts[appointment.patient_id]) {
      counts[appointment.patient_id].count += 1;
    }
  });

  // Convert counts to an array and sort them by count in ascending order
  const countArray = Object.values(counts).sort((a, b) => a.count - b.count);

  // Filter users with more than one meeting and limit them to top 3
  const moreThanOneMeeting = countArray
    .filter((user) => user.count > 1)
    .slice(0, 3);

  // Filter users with zero or one meeting
  const zeroOrOneMeeting = countArray.filter((user) => user.count <= 1);

  // Combine the results
  const result = [...zeroOrOneMeeting, ...moreThanOneMeeting];

  return result;
};

export const getLeastScheduledMeetingsThisMonth = async () => {
  const today = new Date();
  const firstDayOfMonth = formatDate(startOfMonth(today));
  const lastDayOfMonth = formatDate(endOfMonth(today));

  // Fetch all users from the scp table
  const { data: users, error: userError } = await supabase
    .from("scp")
    .select("id");

  if (userError) {
    console.error(userError);
    return [];
  }

  // Fetch this month's appointments
  const { data: appointments, error: appointmentError } = await supabase
    .from("appointments")
    .select("scp_id, patient_id, meeting_date")
    .gte("meeting_date", firstDayOfMonth)
    .lte("meeting_date", lastDayOfMonth);

  if (appointmentError) {
    console.error(appointmentError);
    return [];
  }

  // Initialize counts for all users
  const counts = users.reduce((acc, user) => {
    acc[user.id] = { user_id: user.id, count: 0 };
    return acc;
  }, {});

  // Count the meetings for each SCP and patient
  appointments.forEach((appointment) => {
    if (counts[appointment.scp_id]) {
      counts[appointment.scp_id].count += 1;
    }
    if (counts[appointment.patient_id]) {
      counts[appointment.patient_id].count += 1;
    }
  });

  // Convert counts to an array and sort them by count in ascending order
  const countArray = Object.values(counts).sort((a, b) => a.count - b.count);

  // Filter users with more than one meeting and limit them to top 3
  const moreThanOneMeeting = countArray
    .filter((user) => user.count > 1)
    .slice(0, 3);

  // Filter users with zero or one meeting
  const zeroOrOneMeeting = countArray.filter((user) => user.count <= 1);

  // Combine the results
  const result = [...zeroOrOneMeeting, ...moreThanOneMeeting];

  return result;
};

export const getScpAvailabilityToday = async (scpId, userTz) => {
  try {
    // Get the current date in user's timezone
    const userDate = new Date().toLocaleString("en-US", {
      timeZone: userTz,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    // Convert to YYYY-MM-DD format for database query
    const [month, day, year] = userDate.split("/");
    const formattedDate = `${year}-${month}-${day}`;

    // Calculate the next day for UTC coverage
    const nextDay = new Date(year, month - 1, parseInt(day) + 1).toLocaleString(
      "en-US",
      {
        timeZone: userTz,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }
    );
    const [nextMonth, nextDay_, nextYear] = nextDay.split("/");
    const formattedNextDate = `${nextYear}-${nextMonth}-${nextDay_}`;

    const { data, error } = await supabase
      .from("scp_availability")
      .select(
        `
        *,
        scp:scp_id (timezone)
      `
      )
      .eq("scp_id", scpId)
      .or(`date.eq.${formattedDate},date.eq.${formattedNextDate}`); // Query both dates

    if (error) {
      console.error("Error fetching SCP availability:", error.message);
      return null;
    }

    return data;
  } catch (error) {
    console.error("Error fetching SCP availability:", error.message);
    return null;
  }
};

export const getScpAvailabilityThisWeek = async (scpId, userTz) => {
  try {
    // Get the current date in user's timezone
    const userDate = new Date().toLocaleString("en-US", {
      timeZone: userTz,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    // Convert to Date object
    const [month, day, year] = userDate.split("/");
    const currentDate = new Date(year, month - 1, day);

    // Calculate the first day of the week (Sunday) in user's timezone
    const firstDayOfWeek = new Date(currentDate);
    firstDayOfWeek.setDate(currentDate.getDate() - currentDate.getDay());

    // Calculate dates for the entire week
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);

      // Format each date as YYYY-MM-DD
      const formattedDate = date.toLocaleString("en-US", {
        timeZone: userTz,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const [m, d, y] = formattedDate.split("/");
      dates.push(`${y}-${m}-${d}`);
    }

    // Query Supabase using an array of dates
    const { data, error } = await supabase
      .from("scp_availability")
      .select(
        `
        *,
        scp:scp_id (timezone)
      `
      )
      .eq("scp_id", scpId)
      .in("date", dates); // Use .in() to match any of the dates in the array

    if (error) {
      console.error(
        "Error fetching SCP availability for this week:",
        error.message
      );
      return null;
    }

    return data;
  } catch (error) {
    console.error(
      "Error fetching SCP availability for this week:",
      error.message
    );
    return null;
  }
};

export const getScpAvailabilityThisMonth = async (scpId, userTz) => {
  try {
    // Get the current date in user's timezone
    const userDate = new Date().toLocaleString("en-US", {
      timeZone: userTz,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    // Convert to Date object
    const [month, day, year] = userDate.split("/");
    const currentDate = new Date(year, month - 1, day);

    // Calculate first day of the month in user's timezone
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    // Calculate last day of the month in user's timezone
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    // Generate array of all dates in the month
    const dates = [];
    const currentDay = new Date(firstDayOfMonth);

    while (currentDay <= lastDayOfMonth) {
      const formattedDate = currentDay.toLocaleString("en-US", {
        timeZone: userTz,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const [m, d, y] = formattedDate.split("/");
      dates.push(`${y}-${m}-${d}`);

      currentDay.setDate(currentDay.getDate() + 1);
    }

    // Add one more day to handle timezone overlap
    const extraDay = new Date(lastDayOfMonth);
    extraDay.setDate(extraDay.getDate() + 1);
    const formattedExtraDate = extraDay.toLocaleString("en-US", {
      timeZone: userTz,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const [m, d, y] = formattedExtraDate.split("/");
    dates.push(`${y}-${m}-${d}`);

    // Query Supabase using the array of dates
    const { data, error } = await supabase
      .from("scp_availability")
      .select(
        `
        *,
        scp:scp_id (timezone)
      `
      )
      .eq("scp_id", scpId)
      .in("date", dates);

    if (error) {
      console.error(
        "Error fetching SCP availability for this month:",
        error.message
      );
      return null;
    }

    return data;
  } catch (error) {
    console.error(
      "Error fetching SCP availability for this month:",
      error.message
    );
    return null;
  }
};

export const getScpAvailabilityComingWeek = async (scpId, userTz) => {
  try {
    // Get the current date in user's timezone
    const userDate = new Date().toLocaleString("en-US", {
      timeZone: userTz,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    // Convert to Date object
    const [month, day, year] = userDate.split("/");
    const currentDate = new Date(year, month - 1, day);

    // Calculate dates for the next 7 days
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() + i);

      // Format each date as YYYY-MM-DD
      const formattedDate = date.toLocaleString("en-US", {
        timeZone: userTz,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const [m, d, y] = formattedDate.split("/");
      dates.push(`${y}-${m}-${d}`);
    }

    // Query Supabase using an array of dates
    const { data, error } = await supabase
      .from("scp_availability")
      .select(
        `
        *,
        scp:scp_id (timezone)
      `
      )
      .eq("scp_id", scpId)
      .in("date", dates);

    if (error) {
      console.error(
        "Error fetching SCP availability for coming week:",
        error.message
      );
      return null;
    }

    return data;
  } catch (error) {
    console.error(
      "Error fetching SCP availability for coming week:",
      error.message
    );
    return null;
  }
};

export const getScpAvailabilityComingMonth = async (scpId, userTz) => {
  try {
    // Get the current date in user's timezone
    const userDate = new Date().toLocaleString("en-US", {
      timeZone: userTz,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    // Convert to Date object
    const [month, day, year] = userDate.split("/");
    const currentDate = new Date(year, month - 1, day);

    // Calculate dates for the next 28 days (4 weeks)
    const dates = [];
    for (let i = 0; i < 28; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() + i);

      // Format each date as YYYY-MM-DD
      const formattedDate = date.toLocaleString("en-US", {
        timeZone: userTz,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const [m, d, y] = formattedDate.split("/");
      dates.push(`${y}-${m}-${d}`);
    }

    // Add one more day to handle timezone overlap
    const extraDay = new Date(currentDate);
    extraDay.setDate(currentDate.getDate() + 28);
    const formattedExtraDate = extraDay.toLocaleString("en-US", {
      timeZone: userTz,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const [m, d, y] = formattedExtraDate.split("/");
    dates.push(`${y}-${m}-${d}`);

    // Query Supabase using the array of dates
    const { data, error } = await supabase
      .from("scp_availability")
      .select(
        `
        *,
        scp:scp_id (timezone)
      `
      )
      .eq("scp_id", scpId)
      .in("date", dates);

    if (error) {
      console.error(
        "Error fetching SCP availability for coming month:",
        error.message
      );
      return null;
    }

    return data;
  } catch (error) {
    console.error(
      "Error fetching SCP availability for coming month:",
      error.message
    );
    return null;
  }
};
