import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  Box,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Divider,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CancelIcon from "@mui/icons-material/Cancel";
import { getAllAppointmentsForUser } from "../controllers/appointment";
import { supabase } from "../utils/supabase";
import moment from "moment-timezone";

const styles = {
  accordion: {
    "& .MuiAccordionSummary-root": {
      minHeight: 64,
    },
  },
  meetingItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    py: 2,
    px: 3,
  },
  meetingInfo: {
    flex: 1,
  },
  meetingHeader: {
    display: "flex",
    alignItems: "center",
    mb: 0.5,
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
    gap: 1,
  },
  participants: {
    color: "text.secondary",
    fontSize: "0.875rem",
  },
  dialog: {
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: 400,
      p: 2,
    },
  },
};

const Meetings = ({ user }) => {
  const [meetingsData, setMeetingsData] = useState({
    past: [],
    upcoming: [],
    cancelled: [],
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [isCancelling, setIsCancelling] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    getAllAppointmentsForUser(user.id).then((res) => {
      const now = moment.utc();
      const transformedData = res.reduce(
        (acc, meeting) => {
          const formattedMeeting = formatMeeting(meeting, user.timezone);

          if (meeting.status === "cancelled") {
            acc.cancelled.push(formattedMeeting);
          } else {
            const meetingDate = moment.utc(
              `${meeting.meeting_date}T${meeting.meeting_time.split("-")[0]}`
            );
            if (meetingDate.isBefore(now)) {
              acc.past.push(formattedMeeting);
            } else {
              acc.upcoming.push(formattedMeeting);
            }
          }
          return acc;
        },
        { past: [], upcoming: [], cancelled: [] }
      );
      setMeetingsData(transformedData);
    });
  }, [user.id, user.timezone]);

  const formatMeeting = (meeting, timezone) => {
    const meetingDateUTC = moment.utc(
      `${meeting.meeting_date}T${meeting.meeting_time.split("-")[0]}`
    );
    const meetingDateUserTZ = meetingDateUTC.clone().tz(timezone);
    return {
      id: meeting.id,
      date: meetingDateUserTZ.format("D MMMM YYYY"),
      time: meetingDateUserTZ.format("h:mm A"),
      participants: [
        meeting.patient?.name || meeting.student?.name || "",
        meeting.scp.name,
      ],
      meetingDateUTC,
      status: meeting.status,
    };
  };

  const handleCancelMeeting = async () => {
    if (!selectedMeeting) return;
    setIsCancelling(true);
    try {
      const { error } = await supabase
        .from("appointments")
        .update({ status: "cancelled" })
        .eq("id", selectedMeeting.id);
      if (error) throw error;

      setMeetingsData((prev) => ({
        ...prev,
        upcoming: prev.upcoming.filter(
          (meeting) => meeting.id !== selectedMeeting.id
        ),
        cancelled: [
          ...prev.cancelled,
          { ...selectedMeeting, status: "cancelled" },
        ],
      }));
      setSnackbar({
        open: true,
        message: "Meeting cancelled successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error cancelling meeting:", error);
      setSnackbar({
        open: true,
        message: "Failed to cancel meeting",
        severity: "error",
      });
    } finally {
      setIsCancelling(false);
      setOpenDialog(false);
      setSelectedMeeting(null);
    }
  };

  const generateGoogleCalendarLink = (meeting) => {
    const start = meeting.meetingDateUTC.format("YYYYMMDDTHHmmss[Z]");
    const end = meeting.meetingDateUTC
      .clone()
      .add(1, "hour")
      .format("YYYYMMDDTHHmmss[Z]");
    const details = `Participants: ${meeting.participants.join(", ")}`;
    const text = "Meeting with " + meeting.participants.join(" and ");
    return `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${start}%2F${end}&details=${encodeURIComponent(
      details
    )}&text=${encodeURIComponent(text)}`;
  };

  const renderMeetingItem = (meeting, index, isUpcoming, arrayLength) => (
    <Box key={index}>
      <ListItem sx={styles.meetingItem}>
        <Box sx={styles.meetingInfo}>
          <Box sx={styles.meetingHeader}>
            <Typography variant="subtitle1">
              {`${meeting.date} at ${meeting.time}`}
            </Typography>
            {meeting.status === "cancelled" && (
              <Chip
                label="Cancelled"
                color="error"
                size="small"
                sx={{
                  ml: 1.5,
                  paddingTop: "18px",
                  "& .MuiChip-label": {
                    fontSize: "0.75rem",
                  },
                }}
              />
            )}
          </Box>
          <Typography sx={styles.participants}>
            {`Participants: ${meeting.participants.join(", ")}`}
          </Typography>
        </Box>
        {isUpcoming && meeting.status !== "cancelled" && (
          <Box sx={styles.actionButtons}>
            <Tooltip title="Add to Google Calendar" arrow>
              <IconButton
                color="primary"
                href={generateGoogleCalendarLink(meeting)}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  width: "36px !important",
                  height: "36px !important",
                  padding: "8px !important",
                  backgroundColor: "#fff",
                  border: "1px solid #1976d2",
                  "&:hover": {
                    backgroundColor: "rgba(25, 118, 210, 0.04)",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: "20px !important",
                  },
                }}
              >
                <EventIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel Meeting" arrow>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setSelectedMeeting(meeting);
                  setOpenDialog(true);
                }}
                sx={{
                  width: "36px !important",
                  height: "36px !important",
                  minWidth: "36px !important",
                  padding: "8px !important",
                  backgroundColor: "#fff !important",
                  border: "1px solid #d32f2f !important",
                  color: "#d32f2f !important",
                  borderRadius: "4px",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "rgba(211, 47, 47, 0.04) !important",
                    boxShadow: "none",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: "20px !important",
                  },
                }}
              >
                <CancelIcon />
              </Button>
            </Tooltip>
          </Box>
        )}
      </ListItem>
      {index < arrayLength - 1 && <Divider />}
    </Box>
  );

  return (
    <>
      <Accordion defaultExpanded sx={styles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Upcoming Meetings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {meetingsData.upcoming.map((meeting, index) =>
              renderMeetingItem(
                meeting,
                index,
                true,
                meetingsData.upcoming.length
              )
            )}
            {meetingsData.upcoming.length === 0 && (
              <Typography variant="body2" sx={{ p: 2, textAlign: "center" }}>
                No upcoming meetings
              </Typography>
            )}
          </List>
        </AccordionDetails>
      </Accordion>

      <Box my={2} />

      <Accordion sx={styles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Cancelled Meetings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {meetingsData.cancelled.map((meeting, index) =>
              renderMeetingItem(
                meeting,
                index,
                false,
                meetingsData.cancelled.length
              )
            )}
            {meetingsData.cancelled.length === 0 && (
              <Typography variant="body2" sx={{ p: 2, textAlign: "center" }}>
                No cancelled meetings
              </Typography>
            )}
          </List>
        </AccordionDetails>
      </Accordion>

      <Box my={2} />

      <Accordion sx={styles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Past Meetings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {meetingsData.past.map((meeting, index) =>
              renderMeetingItem(meeting, index, false, meetingsData.past.length)
            )}
            {meetingsData.past.length === 0 && (
              <Typography variant="body2" sx={{ p: 2, textAlign: "center" }}>
                No past meetings
              </Typography>
            )}
          </List>
        </AccordionDetails>
      </Accordion>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        sx={styles.dialog}
      >
        <DialogTitle>Cancel Meeting</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to cancel this meeting? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>No, Keep Meeting</Button>
          <Button
            onClick={handleCancelMeeting}
            color="error"
            variant="contained"
            disabled={isCancelling}
          >
            {isCancelling ? "Cancelling..." : "Yes, Cancel Meeting"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        sx={{
          "& .MuiSnackbarContent-root": {
            display: "flex",
            alignItems: "center",
            gap: 1,
            minHeight: "48px",
          },
        }}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          sx={{
            width: "100%",
            alignItems: "center",
            "& .MuiAlert-action": {
              padding: "0 8px",
              marginRight: 0,
              alignSelf: "center",
            },
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Meetings;
