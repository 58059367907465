import React, { useState, useEffect } from "react";
import moment from "moment";

const CaptureTz = () => {
  const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess());

  useEffect(() => {
    setTimeout(() => {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=19252305898&text=KaalSync%20" +
        selectedTimeZone;
    }, 3000);
  }, [selectedTimeZone]);

  return (
    selectedTimeZone && (
      <div>
        You will be redirected to your Whatsapp with a prefilled message to our
        Bot. Please press send and follow the instructions on Whatsapp
      </div>
    )
  );
};

export default CaptureTz;
