import React, { useState } from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import moment from "moment";
import SearchAllAvailability from "./analytics/SearchAllAvailability";

const BookAllAvailability = ({
  student,
  userRole,
  setIsSuccess,
  scholar = false,
}) => {
  const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess());

  const timezones = moment.tz.names();

  const handleTimeZoneChange = (e) => {
    e.preventDefault();
    setSelectedTimeZone(e.target.value);
  };

  return (
    <Box sx={{ padding: "16px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <FormControl sx={{ minWidth: 200, maxWidth: 300 }} size="small">
          <InputLabel id="timezone-select-label">Your Timezone</InputLabel>
          <Select
            labelId="timezone-select-label"
            id="timezone-select"
            value={selectedTimeZone}
            label="Your Timezone"
            onChange={handleTimeZoneChange}
          >
            {timezones.map((tz) => (
              <MenuItem key={tz} value={tz}>
                {tz}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <SearchAllAvailability
        selectedTimeZone={selectedTimeZone}
        student={student}
        setIsSuccess={setIsSuccess}
        userRole={userRole}
        scholar={scholar}
      />
    </Box>
  );
};

export default BookAllAvailability;
