import { supabase } from "../utils/supabase";

export const getAppointmentById = async (appointmentId) => {
  try {
    const { data, error } = await supabase
      .from("appointments")
      .select(
        `
        *,
        scp:scp_id ( name, timezone ),
        patient:patient_id ( name, timezone ),
        student:student_id ( name, timezone )
      `
      )
      .eq("id", appointmentId)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching Appointment by ID:", error.message);
    return null;
  }
};

export const getAllAppointmentsForUser = async (userId) => {
  const { data, error } = await supabase
    .from("appointments")
    .select(
      `
      *,
      scp:scp_id ( name, timezone ),
      patient:patient_id ( name, timezone ),
      student:student_id ( name, timezone )
    `,
      { count: "exact" }
    )
    .or(`scp_id.eq.${userId},patient_id.eq.${userId}`)
    .order("meeting_date", { ascending: true })
    .order("meeting_time", { ascending: true });

  if (error) console.error(error);
  return data;
};
